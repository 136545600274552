require('dotenv').config({
  path: `.env.${process.env.NODE_ENV}`,
})

const theme = require('./src/theme')

const siteUrl = process.env.SITE_URL || `https://mpatlas.org`

module.exports = {
  siteMetadata: {
    title: `Marine Protection Atlas`,
    description: `The Marine Protection Atlas (MPAtlas.org) was created by the Marine Conservation Institute to more effectively communicate levels of marine protection worldwide.`,
    author: `Marine Conservation Institute`,
    siteUrl,
    contactEmail: `info@mpatlas.org`,
    sentryDSN: process.env.GATSBY_SENTRY_DSN,
    googleAnalyticsId: process.env.GATSBY_GOOGLE_ANALYTICS_ID,
    mapboxToken: process.env.GATSBY_MAPBOX_API_TOKEN,
    tileHost: process.env.GATSBY_TILE_HOST,
    pgTileHost: process.env.GATSBY_PG_TILE_HOST,
    apiHost: process.env.GATSBY_HASURA_GRAPHQL_URL,
  },
  flags: {
    FAST_DEV: false,
    DEV_SSR: false, // appears to throw '"filePath" is not allowed to be empty' when true
    PARALLEL_SOURCING: false, // uses a lot of memory on staging server
  },
  plugins: [
    {
      resolve: `gatsby-plugin-google-gtag`,
      options: {
        trackingIds: process.env.GATSBY_GOOGLE_ANALYTICS_ID
          ? [process.env.GATSBY_GOOGLE_ANALYTICS_ID]
          : [],
        gtagConfig: {
          anonymize_ip: true,
        },
        pluginConfig: {
          head: true,
          respectDNT: true,
        },
      },
    },
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    {
      resolve: 'gatsby-source-graphql',
      options: {
        typeName: 'HASURA',
        fieldName: 'hasura',
        url: `${process.env.INTERNAL_HASURA_GRAPHQL_URL}`,
      },
    },
    {
      resolve: `gatsby-plugin-theme-ui`,
      options: {
        injectColorFlashScript: false,
        preset: theme,
      },
    },
    `gatsby-plugin-image`,
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    {
      resolve: `gatsby-plugin-create-client-paths`,
      options: {
        prefixes: [`/zones/*`, `/sites/*`, `/countries/*`],
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `Marine Protection Atlas`,
        short_name: `MPAtlas.org`,
        start_url: `/`,
        background_color: `#32acd1`,
        theme_color: `#32acd1`,
        display: `minimal-ui`,
        icon: `src/images/icon-192px.png`,
      },
    },
    {
      resolve: `gatsby-plugin-sitemap`,
      options: {
        entryLimit: 5000,
        query: `
      {
        allSitePage {
            nodes {
              path
            }
        }
        hasura {
          countries: outputs_country_core {
            id
          }
          sites: outputs_site_core {
            id: site_id
          }
          zones: outputs_mpa_core {
            id: mpa_id
          }
        }
    }`,
        excludes: [],
        filterPages: () => false,
        resolveSiteUrl: () => siteUrl,
        resolvePages: ({
          allSitePage: { nodes: pages },
          hasura: { countries, sites, zones },
        }) => {
          const paths = pages.map(({ path }) => ({
            path: `${siteUrl}${path}`,
          }))

          paths.push(
            ...countries.map(({ id }) => ({
              path: `${siteUrl}/countries/${id}`,
            }))
          )

          paths.push(
            ...sites.map(({ id }) => ({
              path: `${siteUrl}/sites/${id}`,
            }))
          )

          paths.push(
            ...zones.map(({ id }) => ({
              path: `${siteUrl}/zones/${id}`,
            }))
          )

          return paths
        },
        serialize: ({ path }) => ({
          url: path,
        }),
      },
    },
    {
      resolve: 'gatsby-plugin-robots-txt',
      options: {
        host: process.env.SITE_URL || `https://localhost`,
        policy: [{ userAgent: '*', disallow: ['/services', '/v1/graphql'] }],
      },
    },
  ],
}
