exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-countries-jsx": () => import("./../../../src/pages/countries.jsx" /* webpackChunkName: "component---src-pages-countries-jsx" */),
  "component---src-pages-data-policy-jsx": () => import("./../../../src/pages/data_policy.jsx" /* webpackChunkName: "component---src-pages-data-policy-jsx" */),
  "component---src-pages-glossary-jsx": () => import("./../../../src/pages/glossary.jsx" /* webpackChunkName: "component---src-pages-glossary-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-large-mpas-jsx": () => import("./../../../src/pages/large-mpas.jsx" /* webpackChunkName: "component---src-pages-large-mpas-jsx" */),
  "component---src-pages-methods-index-jsx": () => import("./../../../src/pages/methods/index.jsx" /* webpackChunkName: "component---src-pages-methods-index-jsx" */),
  "component---src-pages-mpaguide-jsx": () => import("./../../../src/pages/mpaguide.jsx" /* webpackChunkName: "component---src-pages-mpaguide-jsx" */),
  "component---src-pages-rbcs-jsx": () => import("./../../../src/pages/rbcs.jsx" /* webpackChunkName: "component---src-pages-rbcs-jsx" */),
  "component---src-pages-sites-jsx": () => import("./../../../src/pages/sites.jsx" /* webpackChunkName: "component---src-pages-sites-jsx" */),
  "component---src-pages-zones-jsx": () => import("./../../../src/pages/zones.jsx" /* webpackChunkName: "component---src-pages-zones-jsx" */)
}

